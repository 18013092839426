import { DatePipe, Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, of, forkJoin, Subscription } from 'rxjs';
import { WebStorage } from 'src/app/demo/components/auth/storage/web.storage';
import { CreateInvoiceComponent } from 'src/app/demo/components/billing/accounting/family/create-invoice/create-invoice.component';
import { AddressDTO } from 'src/app/demo/model/AddressDTO';
import { AllInvoiceCount } from 'src/app/demo/model/AllInvoiceCount';
import { FamilyDTO } from 'src/app/demo/model/FamilyDTO';
import { GuardianDTO } from 'src/app/demo/model/GuardianDTO';
import { InvoiceDTO } from 'src/app/demo/model/InvoiceDTO';
import { RecurringPlansDTO } from 'src/app/demo/model/RecurringPlansDTO';
import { SchoolDTO } from 'src/app/demo/model/SchoolDTO';
import { StudentDTO } from 'src/app/demo/model/StudentDTO';
import { TransactionDTO } from 'src/app/demo/model/TransactionDTO';
import { LookupService } from 'src/app/demo/service/lookup.service';
import { OperationService } from 'src/app/demo/service/operation.service';
import { Common } from 'src/app/demo/utils/common';
import { AddCreditComponent } from '../add-credit/add-credit.component';
import { EditInvoiceComponent } from '../edit-invoice/edit-invoice.component';
import { ReceivePaymentComponent } from '../receive-payment/receive-payment.component';
import { SendInvoiceComponent } from '../send-invoice/send-invoice.component';
import { ViewTransactionRecipentComponent } from '../view-transaction-recipent/view-transaction-recipent.component';
import { LocalOperationService } from 'src/app/demo/service/localOperation.service';

@Component({
  selector: 'app-multi-family-transaction-details',
  templateUrl: './multi-family-transaction-details.component.html',
  styleUrls: ['./multi-family-transaction-details.component.scss'],
})
export class MultiFamilyTransactionDetailsComponent {
  @Input() family!: FamilyDTO;
  incomingFamilyDetails!: FamilyDTO;
  userData: any;
  paidAmount: number = 0;
  inProgressAmount: number = 0;
  unPaidAmount: number = 0;
  familyTransactionList!: TransactionDTO[];
  search: any = {};
  pageContext = {
      page: 0,
      previousPage: 0,
      itemsPerPage: 100,
      totalItems: 0,
      sort: 'id,desc',
  };
  first: number = 0;
  last: number = 0;
  hoveredColumn: string | null = null;
  loading: boolean = true;
  menuItemsInvoice: MenuItem[] = [
      { label: 'View' },
      { label: 'Edit' },
      { label: 'Cancel' },
      { label: 'Send Invoice' },
      { label: 'Receive Payment' },
  ];
  menuItemsPayment: MenuItem[] = [
      { label: 'View Recipent' },
      { label: 'Issue Refund' },
      { label: 'Edit' },
      { label: 'Cancel' },
  ];
  menuitems: MenuItem[] = [
      { label: 'Statement' },
      { label: 'Tax Statement' },
  ];
  menuitemsTransaction: MenuItem[] = [
      { label: 'Create Invoice' },
      { label: 'Add Credit' },
      { label: 'Receive Payment' },
  ];
  menuItemsDelete: MenuItem[] = [{ label: 'Delete' }];
  menuItemsCredit: MenuItem[] = [{ label: 'Edit' }, { label: 'Cancel' }];
  sudent_id: any;
  studentDetails!: StudentDTO;
  showToolbar: boolean = false;
  hoverChip = false;
  currentDate = this.convertToTimeZone(
      this.datePipe.transform(new Date(), 'yyyy-MM-dd') + 'T00:00:00Z'
  );
  visible: boolean = false;
  currentTab: number = 0;
  familyArray: FamilyDTO[] = [];
  localChildArray: StudentDTO[] = [];
  childMap: Map<number, any[]> = new Map();
  parentMap: Map<number, any[]> = new Map();
  isChildEmpty: boolean = false;
  isParentEmpty: boolean = false;
  studentsRecurringPlans: RecurringPlansDTO[] = [];
  multiFamilyList: FamilyDTO[]=[];
  private familySubscription!: Subscription;
  firstTransaction: TransactionDTO = {};
  schooldetails: any;
  imageToShow: string | ArrayBuffer | null | undefined;
  isImageLoading!: boolean;
  picture: any;
  incomingAddress: AddressDTO[] = [];
  address: any;
  addressDetails!: AddressDTO;
    demoAmt: any;

  constructor(private lookupService: LookupService,
    private sanitizer: DomSanitizer,
    private storage: WebStorage,
    private datePipe: DatePipe,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private operationService: OperationService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    // private location: Location,
    private confirmationService: ConfirmationService,
    private localOperationService : LocalOperationService
    
  ) {

  }


  ngOnInit(): void {
    this.sudent_id = this.activatedRoute.snapshot.data['id'];
    this.userData = this.storage.getUserData();
    this.getSchoolDetails();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userData = this.storage.getUserData()
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.incomingFamilyDetails = changes['family'].currentValue;
    this.lookupService.queryStudent({ size: 30, sort: 'fullName,asc', page: 0, 'id.in': this.incomingFamilyDetails.childIds }).subscribe((res: HttpResponse<StudentDTO[]>) => {
      this.incomingFamilyDetails.childs = res.body ? res.body : [];
      this.queryAllInvoiceCount();
      this.incomingFamilyDetails.childs.forEach(element => {
        this.fetchProfileImage(element);
        this.localOperationService.multifamilyobj(this.incomingFamilyDetails);

      });
    })
    this.lookupService.queryGuardian({ size: 30, sort: 'fullName,asc', page: 0, 'id.in': this.incomingFamilyDetails.parentIds }).subscribe((res: HttpResponse<GuardianDTO[]>) => {
      this.incomingFamilyDetails.parents = res.body ? res.body : [];
    })

   

    // setTimeout(() => {

    // }, 2000);
  }

  fetchProfileImage(element: StudentDTO) {
    const queryParams = {
      'docType.equals': 'PROFILE',
      'refTable.equals': 'Student',
      'refTableId.equals': element.id,
    };

    this.lookupService
      .queryAllDocuments(queryParams)
      .subscribe((req: Blob) => {
        if (req.size != 0) {
          let reader = new FileReader();
          reader.addEventListener(
            'load',
            () => {
              let imageToShow: string | ArrayBuffer | any;
              imageToShow = reader.result;
              element.profileimg =
                this.sanitizer.bypassSecurityTrustResourceUrl(
                  '' + imageToShow
                );
            },
            false
          );
          reader.readAsDataURL(req);
        } else {
          element.profileimg = undefined;
        }
      });
  }

  queryAllInvoiceCount() {
    const childIds: number[] = this.incomingFamilyDetails.childs.map(child => Number(child.id));
    const queryParam = {
        'studentId.in': childIds,
        'schoolId.equals': this.userData.schoolId,
        'familyId.equals' : this.incomingFamilyDetails.id
    };
    this.lookupService
        .queryAllInvoiceCount(queryParam)
        .subscribe((res: HttpResponse<AllInvoiceCount>) => {
            this.paidAmount = res.body?.amountPaid ?? 0;
            this.inProgressAmount = res.body?.amountInProcess ?? 0;
            this.unPaidAmount = res.body?.amountUnpaid ?? 0;
        });
}


getSchoolDetails() {
  this.lookupService
      .getSchoolById(this.userData.schoolId)
      .subscribe(async (res: HttpResponse<SchoolDTO>) => {
          this.schooldetails = res.body ?? {};
          this.loadImage(res.body?.logo);
          // this.schooldetails.address = await this.getAddressDetails(
          //     res.body?.id
          // );
          const queryParam = {
              'schoolId.equals': this.userData.schoolId,
              'refTableId.equals': this.userData.schoolId,
              'refTable.equals': 'School',
          };
          this.lookupService
              .queryAddress(queryParam)
              .subscribe((res: HttpResponse<AddressDTO[]>) => {
                  this.incomingAddress = res.body ? res.body : [];
                  if (this.incomingAddress.length > 0) {
                      const firstAddress = res.body![0];
                      this.address =
                          (firstAddress.line ? firstAddress.line + ', ' : '') +
                          (firstAddress.city ? firstAddress.city + ', ' : '') +
                          (firstAddress.state ? firstAddress.state + ', ' : '') +
                          (firstAddress.country ? firstAddress.country + ', ' : '') +
                          (firstAddress.zipcode ? firstAddress.zipcode + '.' : '');
                  }
                  if (this.incomingAddress.length > 0) {
                      this.addressDetails = this.incomingAddress![0];
                  }
              });
      });
  this.getSchoolName();
}
getSchoolName() {
  return this.schooldetails?.name;
}
loadImage(logo: any) {
  this.lookupService
      .queryAllDocuments({
          'fileUuid.equals': logo,
          'status.equals': 'A',
      })
      .subscribe((req: Blob) => {
          if (req.size != 0) {
              this.createImageFromBlob(req);
              this.isImageLoading = false;
          } else {
              this.picture = undefined;
          }
      });
}
createImageFromBlob(image: Blob) {
  let reader = new FileReader();
  reader.addEventListener(
      'load',
      () => {
          this.imageToShow = reader.result;
          this.picture = this.sanitizer.bypassSecurityTrustResourceUrl(
              '' + this.imageToShow
          );
      },
      false
  );
  if (image) {
      reader.readAsDataURL(image);
  }
}

convertStringToArray(idString: string): number[] {
  if (!idString) return [];
  return idString.split(',').map(id => Number(id.trim()));
}

loadTransacList(event: any) {
  if (event.first > event.rows) {
      this.pageContext.page = event.first / event.rows;
  } else if (event.first == event.rows) {
      this.pageContext.page = event.first / event.rows;
  } else {
      this.pageContext.page = 0;
  }
  this.first = event.first;
  this.search = {};
  this.last = this.pageContext.page * event.rows;
  const sortField = event.sortField;
  const sortOrder = event.sortOrder;
  const sortOd =
      sortField !== undefined
          ? sortOrder === 1
              ? 'asc'
              : 'desc'
          : 'desc';
  const sortFld = event.sortField != undefined ? event.sortField : 'id';
  this.pageContext.sort = `${sortFld},${sortOd}`;
  this.pageContext.itemsPerPage = event.rows;

  this.lookupService
      .getStudentById(this.sudent_id)
      .subscribe((res: HttpResponse<StudentDTO>) => {
          this.studentDetails = res.body ?? {};
          const allStudents = [this.studentDetails, ...(this.studentDetails.students ?? [])];
          this.studentDetails.allStudents = allStudents;

          this.studentDetails.allStudents.forEach(element => {
              this.fetchProfileImage(element);

          });

          this.fetchData(this.studentDetails);
          this.queryData();
          this.showToolbar = true;
      });
  this.checkFamilyArrays();
  // setTimeout(() => {

  // }, 1000);
}

queryData() {
  // const siblingIds =
  //     this.studentDetails.students?.map((student) => student.id) ?? [];
      const childIds: number[] = this.incomingFamilyDetails.childs.map(child => Number(child.id));
  this.search.size = this.pageContext.itemsPerPage;
  this.search.page = this.pageContext.page;
  this.search.sort = this.pageContext.sort;
  this.search['status.equals'] = 'A';
  this.search['isLedgerTransaction.equals'] = false;
  this.search['studentId.in'] = childIds;
  this.search['familyId.equals'] = this.incomingFamilyDetails.id;
  Common.removeEmptyFields(this.search);
  this.lookupService
      .queryAllTransaction(this.search)
      .subscribe(async (res: HttpResponse<TransactionDTO[]>) => {
          const temp: StudentDTO[] = [];
          // Use nullish coalescing operator instead of ternary operator
          this.familyTransactionList = res.body ?? temp;

          this.familyTransactionList
              .filter(
                  (item: TransactionDTO) =>
                      item.transactionType === 'Invoice' &&
                      item.isLedgerTransaction === false
              )
              .map(async (item: TransactionDTO) => {
                  await this.fetchInvoice(item);
                  return item; // Return the modified item after fetching the invoice
              });

          setTimeout(() => {
              this.familyTransactionList = this.updateLocalBalanceAmount(
                  this.familyTransactionList
              );
              this.localOperationService.multiFamilyBalance(this.demoAmt);
          }, 2000);

          this.pageContext.totalItems = Number(
              res.headers.get('X-Total-Count')
          );
          this.last =
              this.search.page !== 0
                  ? this.last + this.familyTransactionList.length
                  : this.familyTransactionList.length;
          this.loading = false;
          this.search = {};
      });

  this.queryAllInvoiceCount();

  // Query for recurring plans
  this.lookupService.queryRecurringPlans({
      'planStatus.equals': 'Active',
      'isTamplate.equals': false,
      'status.equals': 'A',
      'schoolId.equals': this.userData.schoolId,
      'studentId.in': childIds,
  }).subscribe((res: HttpResponse<RecurringPlansDTO[]>) => {
      let temp: RecurringPlansDTO[] = [];
      this.studentsRecurringPlans = res.body ? res.body : temp;
  });
}

getmenuItemstransaction(element: any) {
  this.menuitemsTransaction = [
      {
          label: 'Create Invoice',
          command: () => this.createInvoice(),
      },
      {
          label: 'Add Credit',
          command: () => this.addCredit({}, 'Create'),
      },
      {
          label: 'Receive Payment',
          command: () => this.createReceivePmt(element),
      },
  ];
}

getmenuItemsInvoice(element: any, event: Event) {
  this.menuItemsInvoice = [
      {
          label: 'View',
          command: () => this.viewSendInvoice(element),
      },
      {
          label: 'Edit',
          command: () => this.edidInvoice(element),
      },
      {
          label: 'Cancel',
          command: () => this.cancelInvoice(element, event),
      },
      { label: 'Send Invoice', command: () => this.sendInvoice(element) },
      {
          label: 'Receive Payment',
          command: () => this.invoiceReceivePmt(element),
      },
  ];
}
getmenuItemsDelete(element: any, event: Event) {
  this.menuItemsDelete = [
      {
          label: 'Delete',
          command: () => this.deleteMethod(element, event),
      },
  ];
}

getmenuItemsPayment(element: any, event: Event) {
  this.menuItemsPayment = this.menuItemsPayment = element.transactionType !== 'Payment' ? [
      {
          label: 'View Receipt',
          command: () => this.viewRecipent(element),
      },
      {
          label: 'Issue Refund',
          command: () => this.editTransaction(element, true, true),
      },
      {
          label: 'Edit',
          command: () => this.editTransaction(element, false, false),
      },
      {
          label: 'Cancel',
          command: () => this.cancelTransaction(element, event),
      },
    ] : [
        {
            label: 'View Receipt',
            command: () => this.viewRecipent(element),
        },
        {
            label: 'Issue Refund',
            command: () => this.editTransaction(element, true, true),
            disabled: element.isRefund === true,
            icon: element.isRefund ? 'pi pi-exclamation-circle' : undefined
        }
    ];
}

getmenuItemsCredit(element: any, event: Event) {
  this.menuItemsCredit = [
      { label: 'Edit', command: () => this.addCredit(element, 'Edit') },
      {
          label: 'Cancel',
          command: () => this.cancelCredit(element, event),
      },
  ];
}
async fetchInvoice(item: TransactionDTO) {
  const allInvoice = await this.lookupService
      .queryInvoiceById(Number(item.refTableId))
      .toPromise();
  if (allInvoice && allInvoice.body) {
      item.dummyInvoice = { ...allInvoice.body };
      await this.fetchInvoiceDetails(item.dummyInvoice);
  } else {
      item.dummyInvoice = {};
  }

  return item;
}

async fetchInvoiceDetails(item: InvoiceDTO) {
  const queryParams = {
      'status.equals': 'A',
      'refTable.equals': 'Invoice',
      'refTableId.equals': item.id,
  };

  const allInvoiceDetails = await this.lookupService
      .queryInvoiceDetails(queryParams)
      .toPromise();

  if (allInvoiceDetails && allInvoiceDetails.body) {
      item.dummyInvoiceItems = allInvoiceDetails.body;
  } else {
      item.dummyInvoiceItems = [];
  }
  return item;
}

async fetchData(each: StudentDTO) {
  const queryParams = {
      'status.equals': 'A',
      'studentId.equals': each.id,
  };
  try {
      const guardianResponse = await this.lookupService
          .queryGuardian(queryParams)
          .toPromise();

      if (guardianResponse && guardianResponse.body) {
          each.parent = guardianResponse.body;
          this.createFamily(this.studentDetails);
      } else {
          each.parent = undefined;
      }

      this.loading = false;
  } catch (error) {
      console.error('Error fetching guardian data:', error);
      this.loading = false;
  }
  return each;
}

createFamily(studentDetails: StudentDTO) {
  try {
      // Ensure studentDetails.students is an array
      const studentsArray = studentDetails.students ?? [];

      // Convert student ids and parent ids to strings
      const parentIds = studentDetails?.parent?.map((parent) =>
          String(parent.id)
      );

      // Create a temporary array combining studentDetails and studentDetails.students
      const tempStudentArray = [studentDetails, ...studentsArray];
      const childIds = tempStudentArray.map((student) =>
          String(student.id)
      );

      if (studentDetails.parent) {
          // Iterate over tempArray to create FamilyDTO objects
          for (
              let index = 0;
              index < studentDetails.parent.length &&
              index < 2 &&
              tempStudentArray.length;
              index++
          ) {
              let student = tempStudentArray[index];

              // // Fetch profile images for each student
              // this.fetchProfileImage(student).then((updatedStudent :any) => {
              //     tempStudentArray[index] = updatedStudent;

              //     // Manually deep copy tempStudentArray to preserve profileimg field
              //     const deepCopiedStudentArray = tempStudentArray.map(
              //         (stud) => ({
              //             ...stud,
              //             profileimg: stud.profileimg, // Copy the profileimg field
              //         })
              //     );

              //     // Manually deep copy studentDetails.parent to preserve profileimg field
              //     const deepCopiedParentArray = (
              //         studentDetails.parent || []
              //     ).map((parent) => ({
              //         ...parent,
              //         profileimg: parent.profileimg, // Copy the profileimg field
              //     }));

              //     try {
              //         let familyObj: any = {
              //             familyNumber: index + 1,
              //             name: `Family #${index + 1}`,
              //             childIds: childIds.join(', '),
              //             parentIds: parentIds?.join(', ') || '',
              //             studentId: updatedStudent.id || 0, // Handle the case where student.id is undefined
              //             schoolId: studentDetails.schoolId || 0, // Handle the case where schoolId is undefined
              //             status: studentDetails.status || '', // Handle the case where status is undefined
              //             lastModified: studentDetails.lastModified || '', // Handle the case where lastModified is undefined
              //             lastModifiedBy:
              //                 studentDetails.lastModifiedBy || '', // Handle the case where lastModifiedBy is undefined
              //             childs: deepCopiedStudentArray, // Use deep copied array
              //             parents: deepCopiedParentArray, // Use deep copied parent array
              //         };


              //         this.familyArray.push(familyObj);
              //         // Process familyObj as needed, e.g., save to database or log it
              //     } catch (innerError) {
              //         console.error(
              //             `Error processing student at index ${index}:`,
              //             innerError
              //         );
              //     }
              // });
          }
      }

  } catch (error) {
      console.error('Error in createFamily method:', error);
  }
}

// async fetchProfileImage(each: StudentDTO): Promise<StudentDTO> {
//   const queryParams = {
//       'docType.equals': 'PROFILE',
//       'refTable.equals': 'Student',
//       'refTableId.equals': each.id,
//   };

//   try {
//       const req: Blob | undefined = await this.lookupService
//           .queryAllDocuments(queryParams)
//           .toPromise();

//       if (req && req.size !== 0) {
//           const imageToShow = await this.readAsDataURL(req);
//           each.profileimg = this.sanitizer.bypassSecurityTrustResourceUrl(
//               '' + imageToShow
//           );
//       } else {
//           each.profileimg = undefined;
//       }
//   } catch (error) {
//       console.error('Error fetching profile image for student:', error);
//   }

//   return each;
// }

createInvoice() {
  let tempData: any = {
      createInvoice: false,
      familyTransactionCreate: true,
      studentList: Array(this.studentDetails),
  };
  this.ref = this.dialogService.open(CreateInvoiceComponent, {
      data: tempData,
      header: 'Create Invoice',
      width: '65%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
  });
  this.ref.onClose.subscribe((res) => {
      if (res) {
          this.messageService.add(res);
      }
      this.last = 0;
      this.queryData();
  });
}
edidInvoice(element: any) {
  let tempData: any = {
      editInvoice: true,
      invoiceObj: element,
      studentList: Array(this.studentDetails),
  };
  this.ref = this.dialogService.open(EditInvoiceComponent, {
      data: tempData,
      header: 'Edit Invoice',
      width: '65%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
  });
  this.ref.onClose.subscribe((res) => {
      if (res) {
          this.messageService.add(res);
      }
      this.last = 0;
      this.queryData();
  });
}

addCredit(element: any, mode: string) {
  let tempData: any = {
      addCredit: mode != 'Create' ? false : true,
      addEdit: mode != 'Create' ? true : false,
      studentList: Array(this.studentDetails),
      totalAmount: 0,
      obj: element,
  };
  if (tempData.addCredit == true) {
      tempData.totalAmount = this.inProgressAmount + this.unPaidAmount;
  }
  const head = mode != 'Create' ? 'Edit' : 'Add';
  this.ref = this.dialogService.open(AddCreditComponent, {
      data: tempData,
      header: `${head} Credit`,
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
  });
  this.ref.onClose.subscribe((res) => {
      if (res) {
          this.messageService.add(res);
      }
      this.last = 0;
      this.queryData();
      this.setFirstTransaction();
  });
}

viewSendInvoice(event: any) {
  this.studentDetails.invoice = Array(event);
  let obj = this.studentDetails;
  let tempData: any = {
      createInvoice: true,
      invoiceObj: obj,
      schoolDetails: this.schooldetails,
      address: this.addressDetails,
      isAccountList: false,
  };
  this.ref = this.dialogService.open(SendInvoiceComponent, {
      data: tempData,
      header: 'View Invoice',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
  });
  this.ref.onClose.subscribe((res) => {
      if (res) {
          this.messageService.add(res);
      }
      this.last = 0;
      this.queryData();
  });
}

deleteMethod(element: any, event: Event) {
  this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Are you sure you want to delete this ${element.transactionType != undefined
          ? element.transactionType
          : 'Invoice'
          }?`,
      header: `Delete ${element.transactionType != undefined
          ? element.transactionType
          : 'Invoice'
          }`,

        key : 'keyofCancel',        
      

      acceptLabel: 'Yes, Delete',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      acceptButtonStyleClass: 'p-button-danger p-button-raised',
      rejectButtonStyleClass: 'p-button-secondary p-button-raised',
      defaultFocus: 'none',
      accept: () => this.delete(element),
      reject: () => { },
  });
}

cancelInvoice(element: any, event: Event) {
  this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to cancel this invoice?',
      header: 'Cancel Invoice',
      key:"keyofCancel",
      acceptLabel: 'Yes, Cancel',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      acceptButtonStyleClass: 'p-button-danger p-button-raised',
      rejectButtonStyleClass: 'p-button-secondary p-button-raised',
      defaultFocus: 'none',
      accept: () => this.deleteTransaction(element),
      reject: () => { },
  });
}

cancelTransaction(element: any, event: Event) {
  this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to cancel this payment?',
      header: 'Cancel Payment',

      acceptLabel: 'Yes, Cancel',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      acceptButtonStyleClass: 'p-button-danger p-button-raised',
      rejectButtonStyleClass: 'p-button-secondary p-button-raised',
      defaultFocus: 'none',
      accept: () => this.deleteTransaction(element),
      reject: () => { },
  });
}

cancelCredit(element: any, event: Event) {
  this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to cancel this Credit?',
      header: 'Cancel Credit',
      key:"keyofCancel",
      acceptButtonStyleClass: 'p-button-danger p-button-raised',
      rejectButtonStyleClass: 'p-button-secondary p-button-raised',
      acceptLabel: 'Yes, Cancel',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      defaultFocus: 'none',
      accept: () => this.deleteTransaction(element),
      reject: () => { },
  });
}

sendInvoice(event: any) {
  this.studentDetails.invoice = Array(event);
  let obj = this.studentDetails;
  let tempData: any = {
      createInvoice: true,
      invoiceObj: obj,
      schoolDetails: this.schooldetails,
      address: this.addressDetails,
      isAccountList: true,
  };
  this.ref = this.dialogService.open(SendInvoiceComponent, {
      data: tempData,
      header: 'Send Invoice to Family',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
  });
  this.ref.onClose.subscribe((res) => {
      if (res) {
          this.messageService.add(res);
      }
      this.last = 0;
      this.queryData();
  });
}

invoiceReceivePmt(event: any) {
  this.studentDetails.invoice = Array(event);
  let obj = this.studentDetails;
  let tempData: any = {
      isEdit: true,
      isCreate: true,
      invoiceObj: obj,
      openBalanceAmount: this.inProgressAmount + this.unPaidAmount,
      balance: this.demoAmt,
      view : 'MultiFamily',
      isRefund: false,
  };
  this.ref = this.dialogService.open(ReceivePaymentComponent, {
      data: tempData,
      header: 'Receive Payment',
      width: '35%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
  });
  this.ref.onClose.subscribe((res) => {
      if (res) {
          this.messageService.add(res);
      }
      this.last = 0;
      this.queryData();
  });
}

// viewRecipent(event: any) {
//     const foundStudent = event.students.find(
//         (item: StudentDTO) => item.id === this.studentDetails.id
//     );

//     if (foundStudent) {
//         Object.assign(foundStudent, this.studentDetails);
//     }
//     let obj = event;
//     let tempData: any = {
//         viewTransaction: true,
//         transaction: obj,
//         isAccountList: true,
//     };
//     this.ref = this.dialogService.open(ViewTransactionRecipentComponent, {
//         data: tempData,
//         header: 'View Payment Receipt',
//         width: '50%',
//         contentStyle: { overflow: 'auto' },
//         baseZIndex: 10000,
//         maximizable: true,
//     });
//     this.ref.onClose.subscribe((res) => {
//         if (res) {
//             this.messageService.add(res);
//         }
//         this.last = 0;
//         this.queryData();
//     });
// }
viewRecipent(event: any) {
  const foundStudent = event.students.find(
      (item: StudentDTO) => item.id === this.studentDetails.id
  );

  if (foundStudent) {
      Object.assign(foundStudent, this.studentDetails);
  }
  let obj = event;
  let tempData: any = {
      viewTransaction: true,
      transaction: obj,
      schoolDetails: this.schooldetails,
      address: this.addressDetails,
      isAccountList: true,
  };
  this.ref = this.dialogService.open(ViewTransactionRecipentComponent, {
      data: tempData,
      header: 'View Payment Receipt',
      contentStyle: { overflow: 'auto' },
      width: '50%',
      baseZIndex: 10000,
      maximizable: true,
      styleClass: "customModal"
  });

  this.ref.onClose.subscribe((res) => {
      if (res) {
          this.messageService.add(res);
      }
      this.last = 0;
      this.queryData();
  });
}


async createReceivePmt(event: any) {
  const queryParam = {
      'fullName.equals': this.studentDetails.fullName,
      sort: 'id,desc',
  };
  try {
      const res = await this.lookupService
          .queryAllInvoice(queryParam)
          .toPromise();
      const obj = res?.body ?? [];
      this.studentDetails.invoice = obj[0].invoice;
  } catch (error) {
      console.error('Error fetching invoice:', error);
  }

  let invoiceObj = this.studentDetails;
  let tempData: any = {
      isEdit: true,
      isCreate: true,
      invoiceObj: invoiceObj,
      isRefund: false,
      openBalanceAmount: this.inProgressAmount + this.unPaidAmount,
  };
  this.ref = this.dialogService.open(ReceivePaymentComponent, {
      data: tempData,
      header: 'Receive Payment',
      width: '35%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
  });
  this.ref.onClose.subscribe((res) => {
      if (res) {
          this.messageService.add(res);
      }
      this.last = 0;
      this.queryData();
  });
}

async editTransaction(event: any, isEdit: boolean, isRefund: boolean) {
  const foundStudent = event.students.find(
      (item: StudentDTO) => item.id === this.studentDetails.id
  );
  if (foundStudent) {
      Object.assign(foundStudent, this.studentDetails);
  }

  const obj = event.students[0];
  const tempData: any = {
      isEdit: isEdit,
      isCreate: false,
      isRefund: isRefund,
      openBalanceAmount: this.inProgressAmount + this.unPaidAmount,
      invoiceObj: obj,
      view: 'MultiFamily',
      multiFamilyList: this.incomingFamilyDetails,
      paymentObj: event,
      isMultiFmaily: this.incomingFamilyDetails && Object.keys(this.incomingFamilyDetails).length !== 0 ? true : false
  };

  const header =
      tempData.isEdit === false ? 'Edit Payment' : 'Issue Refund';
  try {
      const res = await this.lookupService
          .queryInvoiceById(event.refTableId)
          .toPromise();
      const invoice = res?.body;
      obj.invoice = [invoice];
      obj.transactions = [event];
      tempData.invoiceObj = obj;
  } catch (error) {
      console.error('Error fetching invoice:', error);
  }

  this.ref = this.dialogService.open(ReceivePaymentComponent, {
      data: tempData,
      header: header,
      width: '35%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
  });

  this.ref.onClose.subscribe((res) => {
      if (res) {
          this.messageService.add(res);
      }
      this.last = 0;
      this.queryData();
  });
}

// updateLocalBalanceAmount(transactions: any[]) {
//   for (let i = transactions.length - 1; i >= 0; i--) {
//       if (transactions[i].transactionType === 'Payment') {
//           if (i === transactions.length - 1) {
//               // Boundary case: when it's the last transaction and it's a payment
//               transactions[i].localBalanceAmount = transactions[i].amount;
//           } else if (
//               transactions[i].processStatus === 'Cancelled' ||
//               transactions[i].processStatus === 'Refund'
//           ) {
//               transactions[i].localBalanceAmount =
//                   transactions[i + 1].localBalanceAmount;
//           } else {
//               transactions[i].localBalanceAmount =
//                   transactions[i + 1].localBalanceAmount -
//                   transactions[i].amount;
//           }
//       } else if (transactions[i].transactionType === 'Credit') {
//           if (i === transactions.length - 1) {
//               // Boundary case: when it's the last transaction and it's an invoice
//               transactions[i].localBalanceAmount = transactions[i].amount;
//           } else if (transactions[i].processStatus === 'Cancelled') {
//               transactions[i].localBalanceAmount =
//                   transactions[i + 1].localBalanceAmount;
//           } else {
//               transactions[i].localBalanceAmount =
//                   transactions[i + 1].localBalanceAmount -
//                   transactions[i].amount;
//           }
//       } else if (transactions[i].transactionType === 'Invoice') {
//           if (i === transactions.length - 1) {
//               // Boundary case: when it's the last transaction and it's an invoice
//               transactions[i].localBalanceAmount =
//                   transactions[i].processStatus != 'Cancelled'
//                       ? transactions[i].amount
//                       : transactions[i].balance;
//           } else if (transactions[i].processStatus === 'Cancelled') {
//               transactions[i].localBalanceAmount = transactions[
//                   i + 1
//               ].localBalanceAmount = transactions[i].amount;
//           } else {
//               // transactions[i].localBalanceAmount = transactions[i + 1].localBalanceAmount;
//               transactions[i].localBalanceAmount =
//                   transactions[i].processStatus != 'Cancelled' &&
//                       transactions[i + 1].processStatus != 'Cancelled'
//                       ? transactions[i].amount +
//                       transactions[i + 1].localBalanceAmount
//                       : transactions[i].amount;
//           }
//       }
//   }
//   this.isLocalBalanceAmountZeroOrLess()
//   return transactions;
// }

updateLocalBalanceAmount(transactions: any[]) {
    for (let i = transactions.length - 1; i >= 0; i--) {
        if (transactions[i].transactionType === 'Payment') {
            if (i === transactions.length - 1) {
                // Boundary case: when it's the last transaction and it's a payment
                transactions[i].localBalanceAmount = transactions[i].amount;
                this.demoAmt = transactions[i].localBalanceAmount;
            } else if (
                transactions[i].processStatus === 'Cancelled'
            ) {
                transactions[i].localBalanceAmount =
                    transactions[i + 1].localBalanceAmount;
                this.demoAmt = transactions[i].localBalanceAmount;
            } else {
                transactions[i].localBalanceAmount =
                    transactions[i + 1].localBalanceAmount -
                    transactions[i].amount;
                this.demoAmt = transactions[i].localBalanceAmount;
            }
        } else if (transactions[i].transactionType === 'Refund') {
            if (i === transactions.length - 1) {
                // Boundary case: when it's the last transaction and it's a payment
                // transactions[i].localBalanceAmount = transactions[i].amount;
                transactions[i].localBalanceAmount =
                    transactions[i + 1].localBalanceAmount +
                    transactions[i].amount;
                this.demoAmt = transactions[i].localBalanceAmount;
            } else {
                transactions[i].localBalanceAmount =
                    transactions[i + 1].localBalanceAmount +
                    transactions[i].amount;
                this.demoAmt = transactions[i].localBalanceAmount;
            }
        }
        
        else if (transactions[i].transactionType === 'Credit') {
            if (i === transactions.length - 1) {
                // Boundary case: when it's the last transaction and it's an invoice
                transactions[i].localBalanceAmount = transactions[i].amount;
                this.demoAmt = transactions[i].localBalanceAmount;
            } else if (transactions[i].processStatus === 'Cancelled') {
                transactions[i].localBalanceAmount =
                    transactions[i + 1].localBalanceAmount;
                this.demoAmt = transactions[i].localBalanceAmount;
            } else {
                transactions[i].localBalanceAmount =
                    transactions[i + 1].localBalanceAmount -
                    transactions[i].amount;
                this.demoAmt = transactions[i].localBalanceAmount;
            }
        } else if (transactions[i].transactionType === 'Invoice') {
            if (i === transactions.length - 1) {
                // Boundary case: when it's the last transaction and it's an invoice
                transactions[i].localBalanceAmount =
                    transactions[i].processStatus != 'Cancelled'
                        ? transactions[i].amount
                        : transactions[i].balance;
                this.demoAmt = transactions[i].localBalanceAmount;

            } else if (transactions[i].processStatus === 'Cancelled') {
                // transactions[i].localBalanceAmount = transactions[
                //     i + 1
                // ].localBalanceAmount = transactions[i].amount;
                transactions[i].localBalanceAmount = transactions[i + 1].localBalanceAmount;
                this.demoAmt = transactions[i].localBalanceAmount;

            } else {
                // transactions[i].localBalanceAmount = transactions[i + 1].localBalanceAmount;
                transactions[i].localBalanceAmount =
                    transactions[i].processStatus != 'Cancelled' &&
                        transactions[i + 1].processStatus != 'Cancelled'
                        ? transactions[i].amount +
                        transactions[i + 1].localBalanceAmount
                        : transactions[i].amount;
                this.demoAmt = transactions[i].localBalanceAmount;

            }
        }
    }
    this.isLocalBalanceAmountZeroOrLess();
    return transactions;
}

async delete(item: any) {
  let transactionItem;
  if (item.transactionType != undefined) {
      transactionItem = item;
  } else {
      const queryParam = {
          'refTableId.equals': Number(item.id),
          'transactionType.equals': 'Invoice',
      };
      const allTransactions = await this.lookupService
          .queryAllTransaction(queryParam)
          .toPromise();
      transactionItem = allTransactions?.body?.[0];
  }
  this.operationService.deleteTransaction(transactionItem).subscribe(
      (res: HttpResponse<TransactionDTO>) => {
          this.messageService.add({
              severity: 'success',
              life: 10000,
              summary: 'Success',
              detail: 'Transaction Deleted Successfully',
          });
          this.queryData();
      },
      (error) => {
          this.messageService.add({
              severity: 'error',
              life: 10000,
              summary: error.error.title,
              detail: error.error.detail,
          });
      }
  );
}

async deleteTransaction(item: any) {
  let transactionItem;
  if (item.transactionType != undefined) {
      transactionItem = item;
  } else {
      const queryParam = {
          'refTableId.equals': Number(item.id),
          'transactionType.equals': 'Invoice',
      };
      const allTransactions = await this.lookupService
          .queryAllTransaction(queryParam)
          .toPromise();
      transactionItem = allTransactions?.body?.[0];
  }
  transactionItem.processStatus = 'Cancelled';
  this.operationService.cancelTransaction(transactionItem).subscribe(
      (res: HttpResponse<TransactionDTO>) => {
          this.messageService.add({
              severity: 'success',
              life: 10000,
              summary: 'Success',
              detail: 'Transaction Cancelled Successfully',
          });
          this.queryData();
      },
      (error) => {
          this.messageService.add({
              severity: 'error',
              life: 10000,
              summary: error.error.title,
              detail: error.error.detail,
          });
      }
  );
}

// Helper function to read Blob as Data URL
readAsDataURL(blob: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => resolve(null);
      reader.readAsDataURL(blob);
  });
}

convertToTimeZone(originalDateString: string): string {
  const currentDate = new Date();
  const currentHours = currentDate.getHours().toString().padStart(2, '0');
  const currentMinutes = currentDate
      .getMinutes()
      .toString()
      .padStart(2, '0');
  const currentSeconds = currentDate
      .getSeconds()
      .toString()
      .padStart(2, '0');

  return (
      this.datePipe.transform(originalDateString, 'yyyy-MM-dd') +
      `T${currentHours}:${currentMinutes}:${currentSeconds}Z`
  );
}

getMultipleTagsTooltip(items: any[]): string {
  const tooltipContent = items
      .slice(1)
      .map((item, index) => `${index + 1}. ${item.description}`)
      .join('\n');
  return tooltipContent;
}

// showDialog() {
//     this.visible = true;
//     const siblingIds =
//         this.studentDetails.students?.map((student) => student.id) ?? [];
//     this.lookupService
//         .queryRecurringPlans({
//             'planStatus.equals': 'Active',
//             'isTamplate.equals': false,
//             'status.equals': 'A',
//             'schoolId.equals': this.userData.schoolId,
//             'studentId.in': [this.studentDetails.id, ...siblingIds],
//         })
//         .subscribe((res: HttpResponse<RecurringPlansDTO[]>) => {
//             let temp: RecurringPlansDTO[] = [];
//             this.studentsRecurringPlans = res.body ? res.body : temp;
//         });
//         if (this.familyTransactionList.length > 0) {
//             const firstTransaction = this.familyTransactionList[0];
//             return this.firstTransaction = firstTransaction;
//         } else {
//             return {};
//         }

// }

showDialog() {
  this.visible = true;

  // Retrieve sibling IDs
  const siblingIds = this.studentDetails.students?.map((student) => student.id) ?? [];
  // Check familyTransactionList and set firstTransaction
}

setFirstTransaction() {
  if (this.familyTransactionList.length > 0) {
      this.firstTransaction = this.familyTransactionList[0];
  } else {
      this.firstTransaction = {};
  }
}

isLocalBalanceAmountZeroOrLess(): boolean {
  // Check familyTransactionList and set firstTransaction
  this.setFirstTransaction();

  // Determine if localBalanceAmount is zero or less
  return this.firstTransaction?.localBalanceAmount !== undefined && this.firstTransaction.localBalanceAmount >= 0;
}




closeDialog() {
  this.visible = false;
  this.currentTab = 0;
}

parentById(index: any, parent: any) {
  return parent.familyNumber;
}

initialLetters(text: string): string {
  // Split the text into words
  const words: string[] = text.split(/\s+/);

  // Initialize an empty string to store initial letters
  let initials: string = '';

  // Iterate through each word and extract the initial letter
  for (const word of words) {
      // Add the initial letter to the initials string
      initials += word.charAt(0);
  }

  // Return the initials string
  return initials;
}

// removeChild(studentArray: StudentDTO[], student: StudentDTO){
//     studentArray.forEach((element: StudentDTO, index: number)=>{
//         if(element.id == student.id){
//             studentArray.splice(index,1)
//             this.localChildArray.push(student)
//         }
//     })
// }
removeChild(
  family: FamilyDTO,
  student: StudentDTO,
  familyIndex: number,
  studentIndex: number
) {
  this.familyArray[familyIndex].childs.splice(studentIndex, 1);
  // this.checkFamilyArrays();
  let removedChildren = this.childMap.get(family.familyNumber);
  if (student) {
      family.childIds = this.removeChildId(
          family.childIds,
          String(student.id)
      );
  }
  if (!removedChildren) {
      removedChildren = [];
      this.childMap.set(family.familyNumber, removedChildren);
  }
  removedChildren.push(student);

}

removeChildId(childIds: string, idToRemove: string): string {
  // Split the string into an array, trim each element to remove any extra spaces
  let idsArray = childIds.split(',').map((id) => id.trim());

  // Filter out the ID to remove
  idsArray = idsArray.filter((id) => id !== idToRemove);

  // Join the array back into a string
  return idsArray.join(', ');
}

removeChildFromLocalArray(family: FamilyDTO, child: StudentDTO) {
  // Remove the child from the childMap
  let removedChildren = this.childMap.get(family.familyNumber);
  if (removedChildren) {
      const index = removedChildren.findIndex((c) => c.id == child.id);
      if (index != -1) {
          removedChildren.splice(index, 1);
          // this.checkFamilyArrays();
          this.childMap.set(family.familyNumber, removedChildren);
      }
  }

  // Add the child back to the childs array of the corresponding family
  this.familyArray.forEach((fam) => {
      if (fam.familyNumber == family.familyNumber) {
          fam.childIds = fam.childIds + ',' + child.id;
          fam.childs.push(child);
      }
  });
}

getChildrenByFamilyKey(key: number): StudentDTO[] {
  return this.childMap.get(key) || [];
}

removeParent(
  family: FamilyDTO,
  parent: GuardianDTO,
  familyIndex: number,
  parentIndex: number
) {
  this.familyArray[familyIndex].parents.splice(parentIndex, 1);
  // this.checkFamilyArrays();
  let removedParent = this.parentMap.get(family.familyNumber);
  if (parent) {
      family.parentIds = this.removeParentId(
          family.parentIds,
          String(parent.id)
      );
  }
  if (!removedParent) {
      removedParent = [];
      this.parentMap.set(family.familyNumber, removedParent);
  }
  removedParent.push(parent);


}

removeParentId(parentIds: string, idToRemove: string): string {
  // Split the string into an array, trim each element to remove any extra spaces
  let idsArray = parentIds.split(',').map((id) => id.trim());

  // Filter out the ID to remove
  idsArray = idsArray.filter((id) => id !== idToRemove);

  // Join the array back into a string
  return idsArray.join(', ');
}

removeParentFromLocalArray(family: FamilyDTO, parent: GuardianDTO) {
  // Remove the child from the childMap
  let removedParent = this.parentMap.get(family.familyNumber);
  // this.checkFamilyArrays();
  if (removedParent) {
      const index = removedParent.findIndex((c) => c.id == parent.id);
      if (index != -1) {
          removedParent.splice(index, 1);

          this.parentMap.set(family.familyNumber, removedParent);
      }
  }

  // Add the child back to the childs array of the corresponding family
  this.familyArray.forEach((fam) => {
      if (fam.familyNumber == family.familyNumber) {
          fam.parents.push(parent);
          fam.parentIds = fam.parentIds + ',' + parent.id;
      }
  });
}

getParentByFamilyKey(key: number): StudentDTO[] {
  return this.parentMap.get(key) || [];
}

save() {
  const observables: any = [];
  this.familyArray.forEach((family) => {
      delete (family as any).childs;
      delete (family as any).parents;
      const observable = this.operationService.createFamily(family).pipe(
          catchError((error) => {
              this.messageService.add({
                  key: 'message',
                  severity: 'error',
                  life: 10000,
                  summary: error.error.title,
                  detail: error.error.detail,
              });
              return of(null);
          })
      );
      observables.push(observable);
  });

  forkJoin(observables).subscribe(() => {
      this.onSaveSuccess();
  });
}

protected onSaveSuccess(): void {
  this.messageService.add({
      key: 'message',
      severity: 'success',
      life: 10000,
      summary: 'Success',
      detail: `Family save Successfully.`,
  });
  this.visible = false;
  this.familyArray = [];
  // this.ngOnInit();
  this.queryData();
}

ngDoCheck() {
  this.checkFamilyArrays();
}

checkFamilyArrays() {
  this.isChildEmpty = this.familyArray.some(
      (family) => !family.childs || family.childs.length === 0
  );
  this.isParentEmpty = this.familyArray.some(
      (family) => !family.parents || family.parents.length === 0
  );
}

next() {
  if (this.isLocalBalanceAmountZeroOrLess() && this.studentsRecurringPlans.length == 0 && this.currentTab == 0) {
      this.currentTab += 2;
  } else {
      this.currentTab += 1;
  }

}

previous() {

  if (this.isLocalBalanceAmountZeroOrLess() && this.studentsRecurringPlans.length == 0 && this.currentTab == 2) {
      this.currentTab -= 2;
  } else {
      this.currentTab -= 1;
  }

}

confirmPausePlan(event: Event) {
  this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to pause plans for this family?',
      header: 'Pause Plans',
      acceptLabel: 'Yes, Pause',
      rejectLabel: 'No',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      acceptButtonStyleClass: 'p-button-success p-button-raised',
      rejectButtonStyleClass: 'p-button-secondary p-button-raised',
      defaultFocus: 'none',
      accept: () => this.pausPlan(),
      reject: () => { },
  });
}

pausPlan() {
  this.studentsRecurringPlans.forEach((recurringPlan: RecurringPlansDTO) => {
      recurringPlan.planStatus = 'Paused'
      this.operationService.updateRecurringPlans(recurringPlan).subscribe((response: HttpResponse<RecurringPlansDTO>) => {
          this.messageService.add({
              severity: 'success',
              life: 10000,
              summary: 'Success',
              detail: 'Plan pause Successfully',
          });
          const siblingIds =
              this.studentDetails.students?.map((student) => student.id) ?? [];
          this.lookupService
              .queryRecurringPlans({
                  'planStatus.equals': 'Active',
                  'isTamplate.equals': false,
                  'status.equals': 'A',
                  'schoolId.equals': this.userData.schoolId,
                  'studentId.in': [this.studentDetails.id, ...siblingIds],
              })
              .subscribe((res: HttpResponse<RecurringPlansDTO[]>) => {
                  let temp: RecurringPlansDTO[] = [];
                  this.studentsRecurringPlans = res.body ? res.body : temp;
              });
          this.queryData();
      }, (error) => {
          this.messageService.add({
              severity: 'error',
              life: 10000,
              summary: error.error.title,
              detail: error.error.detail,
          });
      })
  });
}


ngOnDestroy(): void {


        this.localOperationService.multifamilyobj(null);
        this.familySubscription?.unsubscribe();
        this.localOperationService.multiFamilyBalance(undefined);
}
}
