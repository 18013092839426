import * as moment from 'moment';

export class Common {
    static timeZone: string = 'UTC';
    static currentTime = moment().format('HH:mm:ss');
    static currentDate = moment().format('YYYY-MM-DD');
    static format: string = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
    constructor() {}

    static isObjectEmpty(value: Object | any[] | null | undefined): boolean {
        return (
            value === null ||
            value === undefined ||
            value === '' ||
            Object.keys(value).length === 0
        );
    }

    static isEmpty(value: string | any[] | null | undefined): boolean {
        return (
            value === null ||
            value === undefined ||
            value === '' ||
            value === ' ' ||
            value.length === 0
        );
    }

    static isNotEmpty(value: string | any[] | null | undefined): boolean {
        return !Common.isEmpty(value);
    }

    static findById(id: any, items: any[]) {
        items.filter((item) => {
            return item.id === id;
        });
        return null;
    }

    static setSelected(obj: { selected: boolean }) {
        if (obj.selected) {
            obj.selected = false;
        } else {
            obj.selected = true;
        }
    }

    static getNextDate(inputDate: Date): string {
        return new Date(
            new Date(inputDate).getTime() + 60 * 60 * 18 * 1000
        ).toJSON();
    }

    static getTodaysDate(inputDate: Date): string {
        return new Date(
            new Date(inputDate).getTime() + 60 * 60 * 12 * 1000
        ).toJSON();
    }

    static momentTimeZoneDate(date: any): string {
        return moment.utc(date).tz(Common.timeZone).format('YYYY-MM-DD');
    }
    static momentCurrentDate(): string {
        return moment
            .utc(new Date())
            .tz(Common.timeZone)
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }
    static momentTimerZoneTime(): string {
        return moment.utc().format(Common.format);
      }

    static momentTimeZoneTime(date: any): string {
        return moment.utc(date).tz(Common.timeZone).format('HH:mm');
    }

    static parseDate(timestamp: string) {
        const date = moment(timestamp).tz(Common.timeZone);
        return date.format('hh:mm A');
    }

    static removeEmptyFields(obj: any) {
        Object.keys(obj).forEach((key) => {
            if (Common.isEmpty(obj[key])) {
                delete obj[key];
            }
        });
    }

    static toTitleCase(str: string): string {
        return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    }

    static paramMethod(req: any): any {
        let options: boolean = false;
        if (req) {
            if (
                req['fullName.contains'] ||
                req['name.contains'] ||
                req['firstName.contains'] ||
                req['staffId.equals'] ||
                req['studentId.equals']
            ) {
                options = true;
            }
            return options;
        }
    }

    static isPositive(number: number) {
        // Check if the number is a valid number and greater than zero
        return typeof number === 'number' && number > 0;
    }

    static setTimeZone(timeZone: string) {
        return (Common.timeZone = timeZone);
    }

    static isSameMonthInTimezone(dateToCheck: Date | string): boolean {
        // Get the current date in the specified time zone
        const nowInTimezone = moment().tz(Common.timeZone);
        // Convert the dateToCheck to the specified time zone
        const dateToCheckInTimezone = moment(dateToCheck).tz(Common.timeZone);
        // Check if the dates are in the same month and year
        return dateToCheckInTimezone.isSame(nowInTimezone, 'month');
    }

    static getStartOfPreviousMonth(): string {
        return moment()
            .tz(Common.timeZone)
            .subtract(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DDTHH:mm:ssZ');
    }

    static convertToUTC(date: Date): Date {
        const timezone = Common.timeZone;
        if (!timezone) {
            throw new Error('Timezone not set');
        }
        const targetTimezoneOffset = this.getTimezoneOffset(timezone);
        const localTimezoneOffset = date.getTimezoneOffset();
        const targetTime =
            date.getTime() +
            (localTimezoneOffset - targetTimezoneOffset) * 60000;
        return new Date(targetTime);
    }

     static getTimezoneOffset(timezone: string): number {
        // Use moment-timezone to get the timezone offset
        const now = moment().tz(timezone);
        return now.utcOffset();
    }

    static isPreviousDate(selectedDate: string): boolean {
        console.log('timezone', Common.timeZone);
        const currentDate = moment().tz(Common.timeZone);
        const nextMonthLastDate = moment(currentDate)
            .add(1, 'month')
            .endOf('month');
        const selectedMoment = moment.tz(selectedDate, Common.timeZone);
        return selectedMoment.isSameOrBefore(nextMonthLastDate, 'day');
    }

    static findNthLastDayOfMonth(date: Date): string {
        let momentDate = moment(date);
        let lastDayOfMonth = momentDate.clone().endOf('month').date();
        let dayOfMonth = momentDate.date();

        if (dayOfMonth === lastDayOfMonth) {
          return '0';
        } else {
          let nthLast = lastDayOfMonth - dayOfMonth;
          return `-${nthLast}`;
        }
      }
}
